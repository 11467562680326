<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" class="mt-n3">
          <paginated-table
            create-new-item-label="Create New Shop"
            :headers="headers"
            :items="items"
            :pagination-details="paginationDetails"
            :showSearchBox="true"
            :searchPlaceholder="'Search shop name...'"
            @updateSearchValue="searchByValue"
            @createNewItem="createNewItem"
            @editItem="editItem"
            @fetchNextPage="fetchDataByUrl(paginationDetails.nextPageUrl)"
            @fetchPreviousPage="
              fetchDataByUrl(this.paginationDetails.previousPageUrl)
            "
            @updateItemsPerPage="updateItemsPerPage"
            @updateItemStatus="updateItemStatus"
            @updateSelectedPage="updateSelectedPage"
          ></paginated-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import PaginatedTable from "@/components/Tables/PaginatedTable.vue";
export default {
  name: "shops",
  components: {
    HeaderTopDashboard,
    PaginatedTable,
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Owner", value: "owner" },
        { text: "Contact Number", value: "contactNumber" },
        { text: "Orders", value: "orders" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      fullItems: [],
      paginationDetails: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        itemsLength: 0,
        firstPageUrl: "",
        lastPageUrl: "",
        nextPageUrl: "",
        previousPageUrl: "",
        links: [],
        path: "",
      },
      searchValue: "",
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    createNewItem() {
      this.$router.push("/shops/create");
    },

    editItem(item) {
      const originalItem = this.fullItems.find(
        (element) => (element.id = item.id)
      );

      if (!originalItem) {
        this.$notify.error(
          "An error occurred while preparing to modify this shop. Please try again later or contact support."
        );
        return;
      }

      this.$router.push({
        name: "Edit Shop",
        params: {
          id: item.id,
        },
      });
    },

    fetchDataByUrl(url) {
      if (!url) {
        // TODO - print alert.
        return;
      }

      if (!url.includes("search?")) {
        this.paginationDetails.loading = true;
        this.$http
          .get(url, this.sessionStore.getHttpConfig)
          .then((result) => {
            this.items.splice(0);

            for (let i = 0; i < result.data.data.length; i++) {
              const shop = result.data.data[i];
              this.fullItems.push(shop);

              let shopOwnerName = "N/A";
              if (shop.owners && shop.owners.length > 0) {
                shopOwnerName = `${shop.owners[0].firstname} ${shop.owners[0].lastname}`;
              }

              let shopContactNumber = "N/A";
              if (shop.contact_details && shop.contact_details.length > 0) {
                shopContactNumber = shop.contact_details[0].number;
              }

              this.items.push({
                id: shop.id,
                name: shop.name,
                owner: shopOwnerName,
                contactNumber: shopContactNumber,
                orders: shop.orders.length,
                status: shop.status,
              });
            }

            this.setPaginationDetails(result.data);
          })
          .catch((error) => {
            console.error("Error: ", error);
          })
          .finally(() => {
            this.paginationDetails.loading = false;
          });
      } else {
        this.paginationDetails.loading = true;
        const body = [
          {
            field: "name",
            operator: "like",
            value: this.searchValue,
          },
        ];
        this.$http
          .post(url, body, this.sessionStore.getHttpConfig)
          .then((result) => {
            this.items.splice(0);

            for (let i = 0; i < result.data.data.length; i++) {
              const shop = result.data.data[i];
              this.fullItems.push(shop);

              let shopOwnerName = "N/A";
              if (shop.owners && shop.owners.length > 0) {
                shopOwnerName = `${shop.owners[0].firstname} ${shop.owners[0].lastname}`;
              }

              let shopContactNumber = "N/A";
              if (shop.contact_details && shop.contact_details.length > 0) {
                shopContactNumber = shop.contact_details[0].number;
              }

              this.items.push({
                id: shop.id,
                name: shop.name,
                owner: shopOwnerName,
                contactNumber: shopContactNumber,
                orders: shop.orders.length,
                status: shop.status,
              });
            }

            this.setPaginationDetails(result.data);
          })
          .catch((error) => {
            console.error("Error: ", error);
          })
          .finally(() => {
            this.paginationDetails.loading = false;
          });
      }
    },

    searchByValue(val) {
      this.searchValue = val;

      if (!val) {
        this.fetchDataByUrl("/shops");
        return;
      }

      const body = [
        {
          field: "name",
          operator: "like",
          value: val,
        },
      ];

      this.$http
        .post("/shops/search", body, this.sessionStore.getHttpConfig)
        .then((result) => {
          this.items.splice(0);

          for (let i = 0; i < result.data.data.length; i++) {
            const shop = result.data.data[i];
            this.fullItems.push(shop);

            let shopOwnerName = "N/A";
            if (shop.owners && shop.owners.length > 0) {
              shopOwnerName = `${shop.owners[0].firstname} ${shop.owners[0].lastname}`;
            }

            let shopContactNumber = "N/A";
            if (shop.contact_details && shop.contact_details.length > 0) {
              shopContactNumber = shop.contact_details[0].number;
            }

            this.items.push({
              id: shop.id,
              name: shop.name,
              owner: shopOwnerName,
              contactNumber: shopContactNumber,
              orders: shop.orders.length,
              status: shop.status,
            });
          }

          this.setPaginationDetails(result.data);
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$notify.success("An error occurred while searching the shop.");
        });
    },

    updateItemsPerPage(newValue) {
      const searchParams = new URLSearchParams({
        size: newValue,
      });

      const url = `${this.paginationDetails.path}?${searchParams.toString()}`;

      this.fetchDataByUrl(url);
    },

    updateItemStatus(item, status) {
      const body = {
        status,
      };

      this.$http
        .patch(`/shops/${item.id}`, body, this.sessionStore.getHttpConfig)
        .then(() => {
          this.fetchDataByUrl("/shops");
          this.$notify.success("Status updated successfully.");
        })
        .catch((error) => {
          console.error("Error: ", error);
          this.$notify.error("An error occurred while updating the status.");
        });
    },
  },
  mounted() {
    this.fetchDataByUrl("/shops");
  },
};
</script>
